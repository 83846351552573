<script setup>
import Weapon from "@/components/OneVsOne/ProfileUi/AdHoc/Weapon"
import DeletablePane from "@/components/Common/DeletablePane"
import randomWeaponName from "~/utils/crud/randomWeaponName"
import { configAdHocUi } from "~/utils/config"
import UcBtn from "@/components/UC/UcBtn.vue"
import { computed, onMounted } from "vue"

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(["update:modelValue"])

const weapons = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit("update:modelValue", v)
  },
})

const disableAddWeapon = computed(() => {
  return weapons.value.length >= configAdHocUi.maxWeaponCount
})

const disableRemove = computed(() => {
  return weapons.value.length <= 1
})

function addWeapon() {
  const currentWeaponNames = weapons.value.map((weapon) => {
    return weapon.nameUnique
  })
  const newWeapon = {
    attacks: null,
    strength: null,
    ap: 0,
    damage: null,
    id: crypto.randomUUID(),
    nameUnique: randomWeaponName(),
  }
  // Ensure generated weapon names are unique.
  while (currentWeaponNames.includes(newWeapon.nameUnique)) {
    newWeapon.nameUnique = randomWeaponName()
  }
  weapons.value.push(newWeapon)
}

function handleClickAddWeapon() {
  addWeapon()
}

function handleNameUpdated(newName, index) {
  weapons.value[index].nameUnique = newName
}

function handleRemoveWeapon(id, index) {
  weapons.value.splice(index, 1)
}

onMounted(async () => {
  if (weapons.value.length === 0) {
    addWeapon()
  }
})
</script>

<template>
  <div class="pb-4">
    <div class="weapons__header">
      <div class="font-weight-bold mr-4">
        {{ `Weapons: ${weapons.length}` }}
      </div>
      <UcBtn
        elevation="0"
        size="small"
        :disabled="disableAddWeapon"
        data-cy="input-profile-weapon-add"
        @click="handleClickAddWeapon"
        >Add weapon
      </UcBtn>
    </div>
    <DeletablePane
      v-for="(weapon, index) in weapons"
      :key="weapon.id"
      class="mt-4"
      :title="weapon.nameUnique"
      :disable-remove="disableRemove"
      :data-cy="`input-profile-weapon-${index + 1}`"
      @remove="handleRemoveWeapon(weapon.id, index)"
      @title-updated="handleNameUpdated($event, index)"
    >
      <!-- Credit for loop/v-model approach: https://stackoverflow.com/a/68655002 -->
      <Weapon v-model="weapons[index]" />
    </DeletablePane>
  </div>
</template>

<style lang="scss" scoped>
.weapons__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
